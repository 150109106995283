<template>
  <div class="wrappers">
    <div class="login-form">
      <div class="img-box">
        <v-img
          max-height="483px"
          max-width="426px"
          class="left-img"
          src="@/assets/img/sign_in_logo.png"
        ></v-img>
      </div>
      <div class="login-card">
        <div class="login-title">
          <div>金融数字员工</div>
        </div>
        <v-form ref="loginForm" class="loginForm">
          <div class="form-item-label">用户名</div>
          <k-text-input
            v-model="formdata.account"
            outlined
            autofocus
            dense
            label
            class="username"
            placeholder="请输入用户名"
            :prepend-inner-icon="'mdi-account'"
            :rules="[UsernameCanNotEmpty]"
          />
          <div class="form-item-label mt-6">密码</div>
          <k-text-input
            v-model="formdata.password"
            label
            placeholder="请输入密码"
            hint="密码包含大小写英文字母，数字，符号"
            class="password"
            :type="passwordShow ? 'text' : 'password'"
            :rules="[PasswordCanNotEmpty]"
            :prepend-inner-icon="'mdi-onepassword'"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passwordShow = !passwordShow"
          />
          <div class="functions mt-8">
            <!--            <v-checkbox-->
            <!--              v-model="rememberMe"-->
            <!--              :label="`保持登录状态`"-->
            <!--              dense-->
            <!--              hide-details-->
            <!--              class="remember"-->
            <!--              @click="clickRememberMe(rememberMe)"-->
            <!--            />-->
            <v-btn color="primary" :loading="loading" class="signin" @click="loginSubmitHandle">登录</v-btn>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
// import methods from './login.js';
import { mapActions } from 'vuex';
import lodash from 'lodash';
// import axios from 'axios'; // 引入axios

export default {
  name: 'Login',
  data() {
    return {
      passwordShow: false,
      loading: false,
      rememberMe: false,
      formdata: {
        account: '',
        password: '',
      },
    };
  },
  computed: {},
  created() {
    this.init();
  },
  mounted() {
    // axios.post('/mock/news?id=1', { a: 'a' }).then((res) => {
    //   // url即在mock.js中定义的
    //   console.log(res.data); // 打印一下响应数据
    // });
    localStorage.setItem('rememberMe', false);

    // localStorage.clear();
    // sessionStorage.clear();
  },
  methods: {
    ...mapActions(['login']),
    init() {},
    clickRememberMe(value) {
      localStorage.setItem('rememberMe', value);
    },
    UsernameCanNotEmpty() {
      return !!this.formdata.account || '未输入用户名';
    },
    PasswordCanNotEmpty() {
      return !!this.formdata.password || '未输入密码';
    },
    loginSubmitHandle() {
      // eslint-disable-next-line no-debugger
      // debugger;
      const { login, $refs } = this;
      if ($refs.loginForm?.validate()) {
        this.loading = true;

        const param = lodash.cloneDeep(this.formdata);
        param.iv = {};
        login(param).finally(() => {
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@import '~vuetify/src/styles/styles.sass';
$coilSize: 14px;
$delayCount: 40ms;
$scaleMe: 1;

$scaleFlip: 1;
$posFlip: 0;
.wrappers {
  position: relative;
  height: 100vh;
  background: url('../../assets/img/login_bg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 50% 50%;
  .login-form {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1329px;
    height: 783px;
    padding: 131px 121px;
    background: #fff;
    box-shadow: 5px 12px 24px rgba(0, 25, 77, 1);
    border-radius: 20px;
    margin: auto;
    display: flex;
    align-items: center;
    .img-box {
      width: 40%;
    }
    .login-card {
      width: 558px;
      margin-left: 150px;
      padding: $font-size-b5;
      .loginForm {
        max-height: 100%;
      }
      .login-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        > div {
          text-align: center;
          font-size: 40px;
          margin-bottom: 50px;
        }
      }
      .form-item-label {
        margin: 0 0.5em $font-size-s7;
        font-size: $font-size-s1;
        color: #00000099;
      }
      .functions {
        padding: 0 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        ::v-deep .remember {
          label {
            font-size: 13px;
          }
        }
        .signin {
          width: 100%;
        }
      }
    }
  }
}
</style>
